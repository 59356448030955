.News-Header{
    display: grid;
    margin: 0 auto;
    width: 80%;
    margin-top: 50px;
}
.News-Title{
    font-size: 30px;
    text-align: center;
    font-weight: bolder;
    color: rgb(208, 115, 72);
}
.News-Date{
    padding-top: 10px;
    font-size: 19px;
    /* color: #625f5f; */
}
.News-Header h2{
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    color: black;
    text-align: left;
}
.News-Content{
    padding: 10px;
}
.News-Content p{
    text-align: justify;
    line-height: 30px;
}


.Other-News-Cards-Content{
    /* border: 1px solid #ddd; */
    cursor: pointer;
}
.Other-News-Cards-Img-Holder1{
    /* position: absolute; */
    /* width: 25%; */
}
.Other-News-Cards-Heading{
    /* background-color:rgb(226, 207, 207);
    color: black; */
    padding: 10px;
    font-weight: bolder;
    opacity: 0.8;
    /* width: 100%; */
    /* position: relative;
    margin-top: 192px; */
}
.AllNews{
    position: absolute;
    top: 0;
    right: 0;
    margin-top: 80px;
    width: 100px;
    height: 50px;
    background-color: #f9f9f9;
    text-align: center;
    justify-content: center;
    display: flex;
    align-items: center;
    border-radius: 10px;
    cursor: pointer;
    color: #333333;
    transition: all 0.3s;
    
}
.AllNews:hover{
    opacity: 0.6;
}

.date-news{
    /* font-size: 12px; */
    /* font-weight: lighter; */
}



/* All news css */
.News_Background_Container{
    margin-top: 0px;
    padding-top: 0px;
    background-image: url(../assets/newsbackground.jpg);
   background-size: cover;
   text-align: center;
   height: 400px;
   margin-bottom: 20px;
}
.Background_Header_News_Title{
    font-size: 70px;
    text-align: center;
    padding-top: 130px;
    color: white;
    font-weight: bolder;
    font-family: Montserrat,sans-serif;
    text-align: center;
}
.Whats-happening{
    color: white;
    font-size: 24px;
    font-weight: bold;
}
.hype{
    padding-top: 120px;
}
.Other-News-Cards{
    margin: 0 auto;
    width: 90%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 30px;
    padding: 20px;
}
.all-new-individual-title{
    color: rgb(208, 115, 72);
    font-weight: bold;
    font-size: 22px;
    padding-bottom: 0px;
}
.all-news-content{
    font-weight: lighter;
    text-align: justify;
}
.all-news-p{
    font-family: "Trump Mediaeval",serif;
    font-size: 1.25rem;
    font-weight: 400;
    color: #161616;
    line-height: 1.66;
}
.all-news-section-2{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    padding: 50px;
    grid-gap: 20px;
    background-color: whitesmoke;
    margin: 0 auto;
    width: 90%;
    border-radius: 5px;
}
.all-news-single-card{
    margin-top: 0px;
    padding-top: 0px;
    background-image: url(../assets/newsbackground.jpg);
   background-size: cover;
   /* text-align: center; */
   height: 200px;
   border-radius: 5px;
}
.all-news-single-card-content-wrapper{
    background-color: white;
    height: 50px;
    padding: 14px;
    opacity: 0.8;
    margin-top: 123px;
}
.all-news-single-card-title{
    font-size: 14px;
    font-weight: bolder;
    
}
.Search-news{
    color: #ddd;
    width: 100%;
    border: 1px solid #ddd;
}
.All-news-search{
    margin: 0 auto;
    width: 95%;
}
.Similar-News{
    font-size: 25px;
    padding: 20px;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-weight: bolder;
    color: Maroon;
}

/* phone view of all news css starts here */
@media screen and (max-width:650px) {
    .all-news-section-2{
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        padding: 10px;
     
        background-color: whitesmoke;
        margin: 0 auto;
        width: 95%;
        border-radius: 5px;
    }
    .all-news-single-card{
        display: grid;
        grid-column: 1/4;
    }
    .Other-News-Cards{
        margin: 0 auto;
        width: 90%;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 30px;
        padding: 20px;
    }
    .Other-News-Cards-Content{
        display: grid;
        grid-column: 1/4;
    }
    .News_Background_Container{
        margin-top: 0px;
        padding-top: 0px;
        background-image: url(../assets/newsbackground.jpg);
       background-size: cover;
       text-align: center;
       height: 250px;
       margin-bottom: 20px;
    }
    .Background_Header_News_Title{
        font-size: 40px;
        text-align: center;
        padding-top: 90px;
        color: white;
        font-weight: bolder;
        font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
        text-align: center;
    }
    .Whats-happening{
        color: white;
        font-size: 20px;
        font-weight: bold;
    }
    .hype{
        padding-top: 90px;
    }
    
}
/* tablets view of the all news page css starts here */
@media screen and (max-width:963px){
    .all-news-section-2{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        padding: 10px;
        grid-gap: 20px;
        background-color: whitesmoke;
        margin: 0 auto;
        width: 90%;
        border-radius: 5px;
    }
    .Other-News-Cards{
        margin: 0 auto;
        width: 90%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 30px;
        padding: 20px;
    }
}