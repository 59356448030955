
/* .Admission_Header_Title{
    font-size: 40px;
    text-align: center;
    padding-top: 130px;
    color: white;
    font-weight: bolder;
    font-family: Montserrat,sans-serif;
    text-align: center;
}
.Admission-Note{
    color: white;
    font-size: 24px;
    font-weight: bold;
    padding-left: 50px;
    padding-right: 50px;
    text-align: center;
}
.hype{
    padding-top: 120px;
}
.Admission-Cards{
    margin: 0 auto;
    width: 90%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 30px;
    padding: 20px;
} */



/* phone view of Admission Page 
@media screen and (max-width:650px){
    .Admission-Cards{
        margin: 0 auto;
        width: 90%;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 30px;
        padding: 20px;
    }
    
    .Admission_Header_Title{
        font-size: 20px;
        text-align: center;
       padding: 30px;
        color: white;
        font-weight: bolder;
        font-family: Montserrat,sans-serif;
        text-align: center;
    }
    .Admission-Note{
        color: white;
        font-size: 14px;
        font-weight: bold;
       padding: 10px;
        text-align: center;
    }
    
}
/* tablets view of the all admission page css starts here */
/* @media screen and (max-width:963px){
    .Admission-Cards{
        margin: 0 auto;
        width: 90%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 30px;
        padding: 20px;
    }
} */ 

.Admission_Background_Container{
    /* display: none; */
    margin-top: 0px;
    padding-top: 0px;
    background:linear-gradient(rgba(0,0,0,0.7),rgba(0,0,0,0.5)),url(https://media.istockphoto.com/illustrations/admission-concept-on-keyboard-button-3d-rendering-illustration-id675073328?k=20&m=675073328&s=612x612&w=0&h=pIKoyQH3dOzHovks61KmkkMl6pWdk2oMmrVpGvijBuI=);
   background-size: cover;
   text-align: center;
   height: 300px;
   margin-bottom: 20px;
   overflow: hidden;
}
.Admission_Header_Title{
    font-size: 40px;
    text-align: center;
    padding-top: 130px;
    color: white;
    font-weight: bolder;
    font-family: Montserrat,sans-serif;
    text-align: center;
}
.Admission-Note{
    color: white;
    font-size: 24px;
    font-weight: bold;
    padding-left: 50px;
    padding-right: 50px;
    text-align: center;
}
.hype{
    padding-top: 120px;
}
.Admission-Cards{
    margin: 0 auto;
    width: 90%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 30px;
    padding: 20px;
}
.Admission-individual-title{
    /* color: rgb(208, 115, 72); */
    font-weight: bolder;
    font-size: 22px;
    padding-bottom: 0px;
}
.Admissions-content{
    font-weight: lighter;
    text-align: justify;
}
.Admission-Cards-Content{
    border: 1px solid #ddd;
    border-bottom: 2px solid rgb(208, 115, 72);;
    padding: 10px;
    transition: all 0.5s;
    cursor: pointer;
    /* height: fit-content; */
}
.Admission-Cards-Content p{
    color: rgb(208, 115, 72);;
}
.Admission-Cards-Content:hover{
    opacity: 0.6;
    background-color: rgba(208, 115, 72,0.2);
}
/* phone view of Admission Page  */
@media screen and (max-width:650px){
    .Admission-Cards{
        margin: 0 auto;
        width: 90%;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 0px;
        padding: 0px;
    }
    .Admission-Note{
        color: white;
        font-size: 24px;
        font-weight: bold;
        padding-left: 0px;
        padding-right: 0px;
        text-align: center;
    }
    
    .Admission_Header_Title{
        font-size: 20px;
        text-align: center;
       padding: 0px;
        color: white;
        font-weight: bolder;
        font-family: Montserrat,sans-serif;
        text-align: center;
    }
    .Admission-Note{
        color: white;
        font-size: 14px;
        font-weight: bold;
       padding: 0px;
        text-align: center;
    }
    
}
/* tablets view of the all admission page css starts here */
@media screen and (max-width:963px){
    .Admission-Cards{
        margin: 0 auto;
        width: 90%;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 20px;
        padding: 0px;
        overflow: hidden;
    }
}